import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalstorageService } from '../shared/services/localstorage.service';
import { NotificationService } from '../shared/notification/notification.service';
import { CommonService } from '../shared/services/common.service';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
declare var $: any;

declare function update_child(key:string, id:string): void;
@Injectable({
    providedIn: 'root'
})
export class HttpConfigInterceptor implements HttpInterceptor {

    private totalRequests = 0;
    private key = environment.browserKey;
    constructor(private authService: AuthService, private router: Router, private commonService: CommonService,private localStorageService: LocalstorageService, protected _notificationSvc: NotificationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let currentUser
        try{
            currentUser = JSON.parse(localStorage.getItem("_t"));
        } catch (e){}
        if(!this.localStorageService.get('browserInstance')) {
            const instanceId = 'BR-' + uuidv4();
            this.localStorageService.set('browserInstance', instanceId);
        }
        request = request.clone({
            setHeaders: {
                brinstance: encodeURIComponent(this.encrypt(this.localStorageService.get('browserInstance'), this.key))
            }
        })
        if(request.url == "api/refresh-auth-token") {
            request = request.clone({
                setHeaders: {
                    refresh_token: this.localStorageService.get('_rt')
                }
            })
        }
        if(currentUser) {
            request = request.clone({
                setHeaders: {
                    authorization: currentUser
                }
            })
        }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    let data = {};
                    data = {
                        reason: event && event.statusText ? event.statusText : '',
                        status: event.status
                    };
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data: any = {};
                this.hideLoader();
                if (error) {
                    data = {
                        detail: error.message && error.statusText ? error.statusText : null,
                    };
                    if ((error.status)) {

                        if([401, 0].includes(error.status)){
                            update_child('clear', 'Dashboard');
                            this.totalRequests++;
                            CommonService.showLogoutPage = true;
                            console.log(" this.totalRequests++;",  this.totalRequests)
                            setTimeout(() => {
                                $('[data-toggle="tooltip"]').tooltip();
                                $('.nvtooltip').hide();
                            }, 0);
                            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                                // remove user from local storage to log user out
                                setTimeout(() => {
                                    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
                                    let sso_logout_url = userInfo['sso_redirect_url']
                                    this.commonService.resetSelector();
                                    this.localStorageService.remove("_t");
                                    this.localStorageService.remove("userTabInfo");
                                    this.localStorageService.remove("userInfo");
                                    this.localStorageService.remove("activeTabId");
                                    this.localStorageService.remove("activeSubTab");
                                    // this.localStorageService.remove('_rt');
                                    //   if(response['status'] === 'Success'){
                                        if(!request.url.includes("api/refresh-auth-token") && !request.url.includes("api/updateUserActivity")) {
                                            this.totalRequests!=0&&this.totalRequests<=1?this._notificationSvc.warning('Alert', 'Session Timeout!', 5000):'';
                                        }
                                        setTimeout(()=>{this.totalRequests = 0},4000)
                                        if(this.commonService.SSOLogin == undefined || (this.commonService.SSOLogin && this.commonService.SSOLogin.includes('no'))){
                                        this.router.navigate(['/login'], { queryParams: { } });
                                        } else {
                                            if(sso_logout_url) {
                                                window.location.href = sso_logout_url;
                                            } else {
                                                this.router.navigate(['/logout'], { queryParams: {} });
                                            }
                                            // if(request.url.includes("api/refresh-auth-token")) {
                                            //     this.router.navigate(['/login'], { queryParams: { } });
                                            // } else {
                                            //     this.router.navigate(['/login'], { queryParams: { } });
                                            // }
                                        }
                                }, 1000);
                                                      
                        } else {
                            if (data && data.detail && !(error.url.split("/").includes('getFileStatus'))) {
                                this._notificationSvc.warning('Alert', 'Something went wrong, Please try again!', 5000);
                            }     
                        }
                        /* 0 is for unknown error status.
                        There is CORS issue when browser unable to understand response headers so,
                        even though having StatusCode 401 Angular app get StatusCode 0 */
                                                  
                        return throwError(error);
                    } else {
                        return throwError(error);
                    }
                }
                // this._notificationSvc.warning('Alert', 'Something went wrong, Please try again!', 5000);
                // return throwError(error);
            }));
    }

    private showLoader(): void {
        this.commonService.showLoader();
    }

    private hideLoader(): void {
       this.commonService.hideLoader()
    }

    // Step 1: Define encryption and decryption functions
    private encrypt(plaintext, key) {
        var ciphertext = "";
        for (let i = 0; i < plaintext.length; i++) {
            var char = plaintext[i];
            var charCode = char.charCodeAt(0);
            var keyChar = key[i % key.length];
            var keyCharCode = keyChar.charCodeAt(0);
            var encryptedCharCode = (charCode + keyCharCode) % 256;
            ciphertext += String.fromCharCode(encryptedCharCode);
        }
        return ciphertext;
    }

}